<template>
	<el-dialog top="1vh" title="修改记录" width="1050px" :visible.sync="is_show_in_page">
        <!-- 表格 -->
		<div class="tab_height">
			<el-table 
				size="small" 
				height="100%" 
				:data="list.data" 
				:border="true" 
				:stripe="true" 
			>
				<el-table-column label="运单编号" width="195px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作人信息" >
					<template slot-scope="scope" v-if="scope.row.act_user_info">
						<div class="tab_line_item">{{scope.row.act_user_info.name}}/{{scope.row.act_user_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.act_time_text}}</div>
					</template>
				</el-table-column>
                <el-table-column label="操作描述">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.describe}}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>
<script>
import {mapState} from 'vuex'
	export default {
		props:{
			is_show:Number,
			data:Array,
		},
		data() {
			return {
				//页面是否显示
				is_show_in_page:false,

                list:{
                    data:[]
                }
			}
		},
		computed:{
			
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
			
				    this.init();
				}
			},
		},
		methods: {
			init(){
                for(let item of this.data){
                    item.act_time_text = this.$my.other.totime(item.act_time);
                }
                this.list.data = this.data
			},
		}
	}
</script>
<style lang="scss" scoped>

	
</style>